import { EventBus } from "@/components/eventBus";
import { Device } from "@capacitor/device";
import { BleClient, numbersToDataView, ScanMode } from "@capacitor-community/bluetooth-le";
import { Preferences } from "@capacitor/preferences";

export class BLE {
	constructor() {
		this.bleEncoder = new TextEncoder();
		this.MARCO_ERROR_CODE = numbersToDataView(this.bleEncoder.encode('E'));
		this.MARCO_LISTEN_CODE = numbersToDataView(this.bleEncoder.encode('L'));
		this.MARCO_DONE_LISTENING_CODE = numbersToDataView(this.bleEncoder.encode('D'));
		this.MARCO_START_TALKING_CODE = numbersToDataView(this.bleEncoder.encode('T'));
		this.MARCO_STOP_TALKING_CODE = numbersToDataView(this.bleEncoder.encode('t'));
		this.MARCO_PWR_OFF = numbersToDataView(this.bleEncoder.encode('F'));
		this.MARCO_PWR_ON = numbersToDataView(this.bleEncoder.encode('N'));
		this.MARCO_SMILE_CODE = numbersToDataView(this.bleEncoder.encode('S'));
		this.MARCO_FROWN_CODE = numbersToDataView(this.bleEncoder.encode('f'));
		this.MARCO_NEUTRAL_CODE = numbersToDataView(this.bleEncoder.encode('n'));
		this.MARCO_MOUTH_OPEN_CODE = numbersToDataView(this.bleEncoder.encode('o'));
		this.MARCO_START_SERVOS_CODE = numbersToDataView(this.bleEncoder.encode('M'));
		this.MARCO_KILL_SERVOS_CODE = numbersToDataView(this.bleEncoder.encode('k'));
		this.MARCO_RECEIVED_EVENTS = {
			B: {
				name: 'MARCO_BUTTON_PRESSED',
				action: async function () {
					console.log('Button pressed');
					this.EventBus.$emit('button-pressed');
				},
			},
			b: {
				name: 'MARCO_BUTTON_RELEASED',
				action: async function () {
					console.log('Button released');
					this.EventBus.$emit('button-released');
				},
			},
		};
		this.device = null;
		this.readWrite = null;
		this.writeStream = null;
		this.readStream = null;
		this.networkStream = null;
		this.deviceIDStream = null;
		this.objectStream = null;
		this.commandStream = null;
		this.attempts = 0;
		this.scanAttempts = 0;
		this.EventBus = EventBus;
	}

	saveDevice = async () => {
		await Preferences.set({
			key: 'device',
			value: JSON.stringify(this.device),
		});
	}

	loadDevice = async () => {
		const deviceString = await Preferences.get({ key: 'device' });
		if (deviceString.value) {
			this.device = JSON.parse(deviceString.value);
		} else {
			console.log('Device has not been previously connected or could not be found.');
		}
	}
	launchScan = async (maxScanAttempts, callback = false) => {
		const info = await Device.getInfo();

		/*if (info.platform === "ios") {
			EventBus.$emit('open-ios-ble-modal');
			return
		}*/
		this.EventBus.$emit('ble-message', `Starting to scan for MARCo... Attempt ${this.scanAttempts} of ${maxScanAttempts}`);
		this.EventBus.$emit('ble-scan-running', this.scanAttempts);
		let endScanTimeout = setTimeout(async () => {
			await BleClient.stopLEScan();
			console.log('stopped scanning');
			if (!this.device) {
				//A device was not yet found. If it is less than the maximum scans, try again.
				if (this.scanAttempts < maxScanAttempts) {
					this.scanAttempts++;
					console.log('Scan attempt ' + this.scanAttempts);
					this.launchScan(maxScanAttempts, callback);
					EventBus.$emit('ble-message', `Scanning for MARCo... Attempt ${this.scanAttempts} of ${maxScanAttempts}`);

				} else {
					console.log('Scan failed to find a device. Try again.');
					EventBus.$emit('ble-message', `Scan failed to find a device, try again.`);
					this.scanAttempts = 0;
					return callback(false);
				}
			}
		}, 20000);

		setTimeout(async () => {
			await BleClient.requestLEScan(
				{
					//services: [HEART_RATE_SERVICE],
					scanMode: ScanMode.SCAN_MODE_LOW_LATENCY,
					ConnectionPriority: ScanMode.CONNECTION_PRIORITY_HIGH
				},
				async (result) => {
					console.log('received new scan result', JSON.stringify(result));
					if (result.device.name) {
						if (
							result.device.name.toLowerCase().includes('adafruit bluefruit le') ||
							result.device.name.toLowerCase().includes('marco') ||
							result.device.name.toLowerCase().includes('raspberrypi')
						) {
							EventBus.$emit('ble-message', `Found a MARCo! Trying to connect`);
							EventBus.$emit('ble-marco-found');
							console.log('Found the device');
							this.device = result.device;
							console.log("The device is:", this.device);

							this.saveDevice();

							BleClient.stopLEScan();
							try {
								await BleClient.disconnect(device.deviceId);
							} catch (err) {
								console.log('Error disconnecting');
								console.log(err);
							}

							clearTimeout(endScanTimeout);
							this.scanAttempts = 0;
							return callback(true);
						}
					}
				}
			);
		}, 300);
	}

	launchBLE = async (maxScanAttempts = 5) => {
		console.log('Initializing BLE');
		console.log("This is the device", this.device);
		console.log("This is the MARCO_ERROR_CODE", this.MARCO_ERROR_CODE);
		await BleClient.initialize();
		console.log('BLE initialized');
		this.EventBus.$emit('ble-initialized');
		this.EventBus.$emit('ble-message', 'Bluetooth initialized...');
		if (!this.device) {
			try {
				await BleClient.stopLEScan();
			} catch (err) {
				console.log('Error stopping scan');
				console.log(err);
			}

			this.launchScan(maxScanAttempts, async (success) => {
				if (success) {
					console.log('Scan successfully found a device');
					//Now, connect to the device
					// eslint-disable-next-line
					this.attempts = 0;
					return await this.connectToDevice();
				} else {
					console.log('Scan failed to find a device...');
					this.EventBus.$emit('ble-scan-failed');
					this.EventBus.$emit('ble-message', 'Could not find a MARCo, trying rescanning...');
				}
			});
		} else {
			try {
				await BleClient.disconnect(this.device.deviceId);
			} catch (err) {
				console.log('Error disconnecting');
				console.log(err);
			}

			//Now, connect to the device
			this.attempts = 0;
			return await this.connectToDevice();
		}
	}



	connectToDevice = async () => {
		this.EventBus.$emit('ble-connect-attempt', this.attempts);
		try {
			await BleClient.disconnect(this.device.deviceId);
		} catch (err) {
			console.log('Error disconnecting');
			console.log(err);
		}

		try {
			await BleClient.connect(this.device.deviceId);
			//this.EventBus.$emit('ble-connected');
			console.log('Connected to device');
			console.log(this.device);
			const discovery = await BleClient.discoverServices(this.device.deviceId);
			console.log(discovery);

			// return device;
			const services = await BleClient.getServices(this.device.deviceId);
			console.log('Got services', services);
			console.log(services);

			if (this.device.name.toLowerCase().includes('adafruit bluefruit le')) {
				this.readWrite = services[4];
				this.readStream = readWrite.characteristics[0];
				this.writeStream = readWrite.characteristics[1];

				//await BleClient.write(this.device.deviceId, this.readWrite.uuid, this.writeStream.uuid, this.$MARCO_ERROR_CODE);
				// await BleClient.write(this.device.deviceId, this.readWrite.uuid, this.writeStream.uuid, this.$MARCO_LISTEN_CODE);
				//Now, read the characteristics
				/*BleClient.read(device.deviceId, "6e400001-b5a3-f393-e0a9-e50e24dcca9e", "6e400003-b5a3-f393-e0a9-e50e24dcca9e").then((data) => {
						console.log("Read data");
						console.log(data);
					}).catch((err) => {
						console.log("Error reading data");
						console.log(err);
					});*/
				console.log('Connected to the device and got the services and characteristics');
				EventBus.$emit('ble-connected');
				EventBus.$emit('ble-services-received');
				//Start listening for incoming messages
				await BleClient.startNotifications(this.device.deviceId, this.readWrite.uuid, this.readStream.uuid, async (value) => {
					// console.log('current button');
					let nextCommand = await parseSerialString(value);
					console.log('Next command', nextCommand);

					try {
						this.MARCO_RECEIVED_EVENTS[nextCommand.trim()].action();
					} catch (err) {
						console.log('Error parsing command');
						console.log(err);
					}
				});
			}
			else {
				//Now, get the read and write service and characteristics if it is a MARCo-III running on a raspberry pi
				if(services.length > 1){
				this.readWrite = services[2];
				this.networkStream = this.readWrite.characteristics[2];
				this.deviceIDStream = this.readWrite.characteristics[3];
				this.objectStream = this.readWrite.characteristics[4];
				this.commandStream = this.readWrite.characteristics[5];
				this.EventBus.$emit('ble-connected');
			}
			else{
				this.readWrite = services[0];
				this.networkStream = this.readWrite.characteristics[2];
				this.deviceIDStream = this.readWrite.characteristics[3];
				this.objectStream = this.readWrite.characteristics[4];
				this.commandStream = this.readWrite.characteristics[5];
				this.EventBus.$emit('ble-connected');
			}
			}
		} catch (error) {
			console.log('Error connecting to device');
			console.log(error);
			if (this.attempts < 50) {
				this.attempts++;
				this.connectToDevice();
				EventBus.$emit('ble-message', `Error connecting to MARCo. Attempt ${this.attempts} of 50`);
			} else {
				return error;
			}
		}
	}

	parseSerialString = (value) => {
		const decoder = new TextDecoder();
		const decoded = decoder.decode(value);
		return decoded;
	}


}