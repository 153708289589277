/* eslint-disable no-unreachable */
import Vue from 'vue'; //Import the Vue library
import { EventBus } from './components/eventBus.js'; //Import the event bus to allow for communication between components
import App from './App.vue'; //Import the main app page
import Home from './components/Home.vue'; //Import the home page
import Login from './components/Login.vue'; //Import the login page
//import speech from './components/voiceRecTest.vue';
import VueRouter from 'vue-router'; //Import the Vue Router library to manage the navigation between pages
import vuetify from './plugins/vuetify'; //Imports the vuetify library for the front end styling
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import { Preferences } from '@capacitor/preferences'; //Import the preferences plugin to manage local storage
import { SpeechRecognition } from '@capacitor-community/speech-recognition';
import { initializeApp } from 'firebase/app'; //Import the Firebase library
import { getDatabase, ref, set, onValue } from 'firebase/database'; //Import the Firebase Realtime Database library
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'; //Import the Firebase Firestore library
import { getAuth, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth'; //Import the Firebase Authentication library
import { Capacitor } from '@capacitor/core';

import FirstTime from './components/firstTime.vue';




var firebaseConfig = {
  apiKey: 'AIzaSyDSkUUF_ZSBkrH_Y8bS3tnixlyPyETYdpk',
  authDomain: 'newagent-e3a67.firebaseapp.com',
  databaseURL: 'https://newagent-e3a67.firebaseio.com',
  projectId: 'newagent-e3a67',
  storageBucket: 'newagent-e3a67.appspot.com',
  messagingSenderId: '713994289875',
  appId: '1:713994289875:web:f6e024bf677a719d360a26',
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const rt = getDatabase(firebaseApp); //Realtime database
var auth;

if (Capacitor.isNativePlatform()) {
  auth = initializeAuth(firebaseApp, { persistence: indexedDBLocalPersistence });
} else {
  auth = getAuth(firebaseApp);
}

import { BleClient, numbersToDataView } from '@capacitor-community/bluetooth-le';
import Vuetify from 'vuetify/lib/framework';
import NotificationScheduler from './js/notificationScheduler.js';
import { communicationHandler } from './js/communicationHandler.js';
import { BLE } from './js/ble.js';
import  RetentionHandler  from './js/retention.js';

Vue.config.productionTip = false;


function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

function getUrlParam(parameter, defaultvalue) {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  console.log(urlparameter);
  return urlparameter;
}

var isDev = getUrlParam('dev', false);
export const $host = !isDev
  ? 'https://app.marcohealthtech.com'
  : `${window.location.protocol}//${window.location.hostname}`; //If it is just testing the front end, use the production host, otherwise, use the root host
Vue.prototype.$host = $host;
//Vue.prototype.$host = `${window.location.protocol}//${window.location.hostname}`;
console.log('The host is: ' + Vue.prototype.$host);
Vue.prototype.$db = db;
Vue.prototype.$rt = rt;
Vue.prototype.$auth = auth;
Vue.prototype.$speech = SpeechRecognition;
Vue.prototype.$ble = BleClient;

/**
 * @param {string} message - The message to display.
 */
Vue.prototype.$showMessage = function (message) {
  // Your code here
};
const mainNotificationScheduler = new NotificationScheduler();
Vue.prototype.$notification = mainNotificationScheduler;
Vue.prototype.$retentionHandler = new RetentionHandler(mainNotificationScheduler);



const ble = new BLE();

console.log('This is the BLE object', ble);

Vue.prototype.$launchBLE = ble.launchBLE;

Vue.prototype.$marcoError = async function (callback = null) {
  //Check if the device exists
  if (ble.device !== null) {
    try {
      await BleClient.write(ble.device.deviceId, ble.readWrite.uuid, ble.writeStream.uuid, ble.MARCO_ERROR_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoListen = async function (callback = null) {
  if (ble.device !== null) {
    try {
      await BleClient.write(ble.device.deviceId, ble.readWrite.uuid, ble.writeStream.uuid, ble.MARCO_LISTEN_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoStartTalking = async function (callback = null) {
  if (ble.device !== null) {
    try {
      await BleClient.write(
        ble.device.deviceId,
        ble.readWrite.uuid,
        ble.writeStream.uuid,
        ble.MARCO_START_TALKING_CODE,
      );
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoStopTalking = async function (emotion = 'happy', callback = null) {
  if (ble.device !== null) {
    try {
      await BleClient.write(ble.device.deviceId, ble.readWrite.uuid, ble.writeStream.uuid, ble.MARCO_STOP_TALKING_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoSmile = async function (callback = null) {
  if (ble.device !== null) {
    try {
      await BleClient.write(ble.device.deviceId, ble.readWrite.uuid, ble.writeStream.uuid, ble.MARCO_SMILE_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoFrown = async function (callback = null) {
  if (ble.device !== null) {
    try {
      await BleClient.write(ble.device.deviceId, ble.readWrite.uuid, ble.writeStream.uuid, ble.MARCO_FROWN_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoNeutral = async function (callback = null) {
  if (ble.device !== null) {
    try {
      await BleClient.write(ble.device.deviceId, ble.readWrite.uuid, ble.writeStream.uuid, ble.MARCO_NEUTRAL_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

Vue.prototype.$marcoOpen = async function (callback = null) {
  if (ble.device !== null) {
    try {
      await BleClient.write(ble.device.deviceId, ble.readWrite.uuid, ble.writeStream.uuid, ble.MARCO_MOUTH_OPEN_CODE);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

const globalAudio = new Audio();
const commlink = new communicationHandler(Preferences, db, rt);
commlink.initializeCommlink();
const sendDockID = async function (dockID) {
  globalAudio.src = '/assets/audio/sfx/marco-sender.mp3';
  globalAudio.play();
  if (ble.device !== null) {
    try {
      await BleClient.write(
        ble.device.deviceId,
        ble.readWrite.uuid,
        ble.deviceIDStream.uuid,
        numbersToDataView(ble.bleEncoder.encode(dockID)),
      );
      EventBus.$emit('dock-id-sent', dockID);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
      EventBus.$emit('dock-id-send-failed', dockID);
    }
  }
};

Vue.prototype.$sendDockID = sendDockID;

Vue.prototype.$getDefaultDockID = async function () {
  //eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    if (ble.device !== null) {
      try {
        const defaultDockBuffer = await BleClient.read(
          ble.device.deviceId,
          ble.readWrite.uuid,
          ble.deviceIDStream.uuid,
        );
        let defaultDock = '';
        for (var i = 0; i < defaultDockBuffer.buffer.byteLength; i++) {
          defaultDock += String.fromCharCode(defaultDockBuffer.getUint8(i));
        }
        globalAudio.src = '/assets/audio/sfx/marco-receiver.mp3';
        globalAudio.play();
        resolve(defaultDock);
      } catch (e) {
        console.log('There was an error writing to the device:', e);
        reject(e);
      }
    }
  });
};

Vue.prototype.$getDeviceNetworkStatus = async function () {
  //eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    if (ble.device !== null) {
      try {
        const networkStatusBuffer = await BleClient.read(
          ble.device.deviceId,
          ble.readWrite.uuid,
          ble.networkStream.uuid,
        );
        let networkStatusObjString = '';
        for (var i = 0; i < networkStatusBuffer.buffer.byteLength; i++) {
          networkStatusObjString += String.fromCharCode(networkStatusBuffer.getUint8(i));
        }
        let networkStatusObj = JSON.parse(networkStatusObjString);
        console.log('networkStatusObj', networkStatusObj);
        let networkStatus = typeof networkStatusObj === 'object' ? networkStatusObj.status : networkStatusObj;
        networkStatus = networkStatus.toLowerCase() === 'true';
        globalAudio.src = '/assets/audio/sfx/marco-receiver.mp3';
        globalAudio.play();
        resolve({
          status: networkStatus,
          attempting: networkStatus.connectionAttempts,
        });
      } catch (e) {
        console.log('There was an error reading the network status from the device:', e);
        reject(e);
      }
    }
  });
};

Vue.prototype.$sendDeviceNetwork = async function (ssid, password = '') {
  globalAudio.src = '/assets/audio/sfx/marco-sender.mp3';
  globalAudio.play();

  let msg = `WIFI:WPA;${password.length > 0 ? 'P:' + password + ';' : ''}S:${ssid};U:${commlink.dockID}`;
  var encodedUnicodeString = numbersToDataView(ble.bleEncoder.encode(msg));
  console.log(encodedUnicodeString);
  if (ble.device !== null) {
    try {
      await BleClient.write(ble.device.deviceId, ble.readWrite.uuid, ble.networkStream.uuid, encodedUnicodeString, {
        timeout: 10000,
      });
      EventBus.$emit('network-sent');
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

/**
 * @function sendObject
 * @description Sends an object/file to the MARCo device
 * @param {String} object - the stringified version of the data to send
 * @param {String} path - the end file path of data being sent
 */

Vue.prototype.$sendObject = async function (object, path) {
  globalAudio.src = '/assets/audio/sfx/marco-sender.mp3';
  globalAudio.play();

  const val = { object: object, path: path };
  if (ble.device !== null) {
    try {
      await BleClient.write(
        ble.device.deviceId,
        ble.readWrite.uuid,
        ble.objectStream.uuid,
        numbersToDataView(ble.bleEncoder.encode(val)),
        { timeout: 10000 },
      );
      EventBus.$emit('object-sent', path);
    } catch (e) {
      console.log('There was an error writing to the device:', e);
    }
  }
};

/**
 * @function readObject
 * @description Reads an object/file from the MARCo device
 * @param {String} path - the end file path of data to be recovered
 * @returns {String} - the stringified version of the data recovered
 */

Vue.prototype.$readObject = async function (path) {
  //eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    if (ble.device !== null) {
      console.log('Yes, we are reading it!');
      try {
        //First, write to the device the path of the file to be read
        const val = { read: path };
        console.log('Just about to write to device', val, 'with path', path);
        var encodedUnicodeString = numbersToDataView(ble.bleEncoder.encode(JSON.stringify(val)));
        await BleClient.write(ble.device.deviceId, ble.readWrite.uuid, ble.objectStream.uuid, encodedUnicodeString, {
          timeout: 10000,
        });
        console.log('Wrote to device');

        //Then, read the file from the device
        const objectBuffer = await BleClient.read(ble.device.deviceId, ble.readWrite.uuid, ble.objectStream.uuid);
        let objectString = '';
        for (var i = 0; i < objectBuffer.buffer.byteLength; i++) {
          objectString += String.fromCharCode(objectBuffer.getUint8(i));
        }

        //The objectString is a stringified version of the object, so parse to JSON
        objectString = JSON.parse(objectString);

        var result = objectString.contents;

        console.log('objectString', objectString);

        //The objectString contains four parameters, one is the currentChunk and the other is the maxChunk. If the currentChunk is less than the maxChunk, then we need to continue reading and appending to the contents
        while (parseInt(objectString.chunksLeft) > 0) {
          const objectBuffer = await BleClient.read(ble.device.deviceId, ble.readWrite.uuid, ble.objectStream.uuid);
          objectString = '';
          for (var i = 0; i < objectBuffer.buffer.byteLength; i++) {
            objectString += String.fromCharCode(objectBuffer.getUint8(i));
          }
          objectString = JSON.parse(objectString);
          result += objectString.contents;

          console.log('There are ' + objectString.chunksLeft + ' chunks left');
        }

        globalAudio.src = '/assets/audio/sfx/marco-receiver.mp3';
        globalAudio.play();

        resolve(result);
      } catch (e) {
        console.log('There was an error writing to the device:', e);
        reject(e);
      }
    }
  });
};

EventBus.$on('checkDockID', async (param) => {
  let isEqual = commlink.compareDockIDs(param);

  console.log('Dock ID is equal? ' + isEqual);
  if (!isEqual) {
    sendDockID(commlink.dockID);
  } else {
    EventBus.$emit('dock-id-valid');
    EventBus.$emit('connectionStatus', true);
  }
});

const completeDeviceSetup = async () => {
  await Preferences.set({
    key: 'visited',
    value: 'yes',
  });
};

const factoryReset = async () => {
  await Preferences.set({
    key: 'visited',
    value: 'no',
  });
};

EventBus.$on('factory-reset', async () => {
  await factoryReset();
});

EventBus.$on('setup-finished', async () => {
  await completeDeviceSetup();
});

//Create the router for switching to different pages
Vue.use(VueRouter);

Vue.use(Vuetify);

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      redirect: async (to) => {
        console.log('Trying to redirect to the appropriate page...');
        /*function getCookie(c_name) {
					var c_value = document.cookie;
					var c_start = c_value.indexOf(" " + c_name + "=");
					if (c_start == -1) {
						c_start = c_value.indexOf(c_name + "=");
					}
					if (c_start == -1) {
						c_value = null;
					} else {
						c_start = c_value.indexOf("=", c_start) + 1;
						var c_end = c_value.indexOf(";", c_start);
						if (c_end == -1) {
							c_end = c_value.length;
						}
						c_value = c_value.substring(c_start, c_end);
					}
					return c_value;
				}*/

        const checkSession = async () => {
          const c = await Preferences.get({ key: 'visited' });
          console.log('Visited: ', c.value);
          if (c.value === 'yes') {
            console.log('Redirecting to home page...');
            return '/home';
          } else {
            console.log('Redirecting to welcome page...');
            return '/firstTime';
          }

          //setCookie("visited", "yes", 365); // expire in 1 year; or use null to never expire
        };
        let result = await checkSession();
        console.log('Result of checkSession: ', result);
        console.log(typeof result);
        console.log(router);
        return router.push(result);
      },
    },
    { path: '/home', component: Home },
    { path: '/login', component: Login },
    //{ path: '/speech', component: speech }

    { path: '/firstTime', component: FirstTime },
  ],
});

//Initialize the application
new Vue({
  vuetify,
  router,
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  themes: {
    light: {
      primary: '#4caf50',
      secondary: '#03a9f4',
      accent: '#009688',
      error: '#f44336',
      warning: '#ff9800',
      info: '#2196f3',
      success: '#8bc34a',
    },
    dark: {
      primary: '#006117',
      secondary: '#0173BA',
      accent: '#01baa4',
      error: '#e73120',
    },
  },

  render: (h) => h(App),
}).$mount('#app');
