class RetentionHandler {
  constructor(notificationHandler) {
    this.notificationHandler = notificationHandler;
    this.onboardingLessons = [
    {
            title: "Welcome to MARCo! - 60 Day Coaching",
            description: "Get to know your new robot BFF with these 3 simple steps",
            duration: 66, //Duration in seconds
            videoLink: "https://www.youtube.com/embed/PWLgd_HZWyo",
            headerImage: "onboarding-vid-0.webp",
            locked: false,
            week: 0
        },

      {
        title: "Week 1 - Get to Know MARCo",
        description: "Get to know your new robot BFF with these 3 simple steps",
        duration: 78, //Duration in seconds
        videoLink: "https://www.youtube.com/embed/dswE1_hUmTE",
        headerImage: "onboarding-vid-1.webp",
        locked: false,
        week: 1
      },
      {
        title: "Week 2 - Talking to MARCo",
        description: "Learn the different ways to talk to MARCo and get the most out of your conversations",
        duration: 105,
        videoLink: "https://www.youtube.com/embed/JMujiP7BaS0",
        headerImage: "onboarding-vid-2.webp",
        locked: true,
        week: 2
      },
      {
        title: "Week 3 - A Bit of Psychoeducation",
        description: "Learn the basics of the science behind MARCo and how it can help you feel better",
        duration: 75,
        videoLink: "https://www.youtube.com/embed/NDA5TIkaNsI",
        headerImage: "onboarding-vid-3.webp",
        locked: true,
        week: 3
      },
      {
        title: "Week 4 - Setting Goals",
        description: "Learn how to set goals and track your progress with MARCo",
        duration: 133,
        videoLink: "https://www.youtube.com/embed/jFUSchx3N8w",
        headerImage: "onboarding-vid-4.webp",
        locked: true,
        week: 4
      },
      {
        title: "Week 5 - Practicing Coping Skills",
        description: "Find what works best for you by practicing different coping skills with MARCo",
        duration: 156,
        videoLink: "https://www.youtube.com/embed/cHqUQrtYMeQ",
        headerImage: "onboarding-vid-5.webp",
        locked: true,
        week: 5
      },
      {
        title: "Week 6 - Managing Negative Thoughts",
        description: "Quiet that negative voice in your head with MARCo's help!",
        duration: 159,
        videoLink: "https://www.youtube.com/embed/3dvHBytjHIc",
        headerImage: "onboarding-vid-6.webp",
        locked: true,
        week: 6
      },
      {
        title: "Week 7 - Building Resilience",
        description: "Stregthen your ability to bounce back from life's challenges with MARCo",
        duration: 125,
        videoLink: "https://www.youtube.com/embed/_pAxuFBqbSQ",
        headerImage: "onboarding-vid-7.webp",
        locked: true,
        week: 7
      },
      {
        title: "Week 8 - Three R's of Mental Health",
        description: "Reflect, Repeat, Reexamine - the three R's of mental health with MARCo",
        duration: 138,
        videoLink: "https://www.youtube.com/embed/dcH-IewKg2g",
        headerImage: "onboarding-vid-8.webp",
        locked: true,
        week: 8
      },
      {
        title: "Week 9 - Looking Ahead",
        description: "Find out what your long term goals and plans are with MARCo",
        duration: 183,
        videoLink: "https://www.youtube.com/embed/Wk8e6WZwq9I",
        headerImage: "onboarding-vid-9.webp",
        locked: true,
        week: 9
      }
      // Add more lessons here...
    ];
  }

  getLessonArray(date) {
    const weekNumber = this.calculateWeekNumber(date);
    let outputs = this.unlockLessonsUntilWeek(weekNumber);
    return outputs;
  }

  scheduleNotifications() {
    const lessonArray = this.getLessonArray(new Date());
    lessonArray.forEach((lesson, index) => {
      const notificationDate = calculateNotificationDate(index + 1);
      this.notificationHandler.scheduleNotification(notificationDate, `Coaching Lesson ${index + 1}`);
    });
  }

  unlockLessonsUntilWeek(weekNumber) {
    let output = {
        active: false,
        lessons:[]
    }
    for (let i = 0; i < this.onboardingLessons.length; i++) {
        const lesson = this.onboardingLessons[i];
        if (lesson.week < weekNumber) {
            lesson.locked = false;
            output.lessons.push(lesson);
        }
        else if(lesson.week === weekNumber){
            lesson.locked = false;
            output.active = lesson;
        }
        else{
            lesson.locked = true;
            output.lessons.push(lesson);
        }
    }

    return output;
  }

calculateWeekNumber(date) {
    const currentDate = new Date();
    const weekDifference = Math.floor((currentDate - date) / (1000 * 60 * 60 * 24 * 7));
    return weekDifference + 1;
}

  calculateNotificationDate(lessonIndex) {
    // Calculate the notification date based on the lesson index
    // Implement your own logic here
  }
}

export default RetentionHandler