import { render, staticRenderFns } from "./TimerWidget.vue?vue&type=template&id=59af5f14&"
import script from "./TimerWidget.vue?vue&type=script&lang=js&"
export * from "./TimerWidget.vue?vue&type=script&lang=js&"
import style0 from "./TimerWidget.vue?vue&type=style&index=0&id=59af5f14&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports