import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  themes: {
    light: {
      primary: '#4caf50',
      secondary: '#03a9f4',
      accent: '#009688',
      error: '#f44336',
      warning: '#ff9800',
      info: '#2196f3',
      success: '#8bc34a',
    },
    dark: {
      primary: '#006117',
      secondary: '#0173BA',
      accent: '#01baa4',
      error: '#e73120',
    },
  },
});
